/*
  This file should keep all global variables like paddings / borders hights, ets.
  You can also link this file to your component's styles using construction:

  @import '../../../../scss/values';

  (count of '../' depends on the component path )
*/

/*! Screen size triggers! */

$mobile-min-375px-width-trigger: 375px;
$mobile-min-425px-width-trigger: 425px;
$mobile-min-450px-width-trigger: 450px;
$mobile-min-500px-width-trigger: 500px;
$mobile-min-sm-width-trigger: 540px;
$mobile-min-550px-width-trigger: 550px;
$mobile-min-575px-width-trigger: 575px;
$mobile-600px-width-trigger: 600px;
$mobile-670px-width-trigger: 670px;
$mobile-max-width-trigger: 767px;
$tablet-min-width-trigger: 768px;
$tablet-800px-width-trigger: 800px;
$tablet-991px-width-trigger: 991px;
$tablet-992px-width-trigger: 992px;
$large-tablet-min-width-trigger: 1024px;
$tablet-large-width-trigger: 1024px;
$tablet-max-width-1130px-trigger: 1130px;
$tablet-max-width-trigger: 1199px;
$desktop-min-width-trigger: 1200px;
$desktop-min-width-1240px-trigger: 1240px;
$desktop-min-width-1300px-trigger: 1300px;
$desktop-min-width-1366px-trigger: 1366px;
$desktop-min-1367-trigger: 1367px;
$desktop-extra-large: 2800px;

/*================================*/

$trebuchet-font: 'Trebuchet MS', roboto, ubuntu, sans-serif;
$yantramanav-font: Yantramanav, roboto, ubuntu, sans-serif;

$small-indent: 16px;
$big-indent: 24px;
$control-border-radius: 5px;

$small-indent-mobile: 10px;
$big-indent-mobile: 18px;

$simple-border: 1px solid var(--light-border-color);
$dark-border: 1px solid var(--dark-border-color);

$modal-icon-size: 40px;
$modal-close-icon-button-size: 24px;
$modal-close-icon-size: 16px;
$modal-action-button-height: 36px;
$modal-action-min-width: 96px;

$placeholder-opacity: 0.7;
$form-label-indent: $small-indent * 0.5;

$standard-chip-height: 40px;

// Icons
$big-svg-icon-size: 32px;
$standard-svg-icon-size: 24px;
$middle-svg-icon-size: 20px;
$small-svg-icon-size: 15px;
$small-svg-icon-14px-size: 14px;
$extra-small-svg-icon-size: 12px;
$small-svg-icon-16px-size: 16px;
$small-svg-icon-18px-size: 18px;
$extra-small-svg-icon: 10px;

// Buttons
$standard-size: 32px;
$large-size: 40px;
$middle-size: 24px;

// Badge
$extra-small-badge-size: 12px;

// custom css var to define top-header height value for all devices
//TODO: remove new-header-height after release new header template
:root {
  --header-height: 87px;
  --new-header-height: 66px;
  --top-nav-header-height: 120px;

  @media all and (max-width: $tablet-991px-width-trigger) {
    --top-nav-header-height: 65px;
  }

  @media all and (max-width: $mobile-max-width-trigger) {
    --new-header-height: 34px;
    --top-nav-header-height: 35px;
  }
}
