@import '../../../../scss/values';

app-scanner-symbols-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: $tablet-991px-width-trigger) {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 230px; // TODO: check how to use 100% and set specific values in the layout
  }
}

.mat-row {
  scroll-margin-top: 30px; // current height of mat-header-row
}

.table-sort-header {
  flex: 0 0 auto;
}

.scanner-symbols-list-virtual-scroll {
  flex: 1 1 auto;
}

.table-header-row.hidden {
  height: 0;
  visibility: hidden;
}

.remove-btn.hidden {
  display: none;
}

.wtf-scanner-virtual-scroll {
  height: 100%;
  padding-bottom: 16px;

  .wheel-conservative-aggressive-table.wtf-scanner-table,
  .scanner-symbol-table {
    padding-bottom: 2px;

    .company-cell-content.with-flags-statistic {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 4px;

      .company-title {

      }

      .flag-statistics-widget {

      }
    }
  }

  @media screen and (max-width: $tablet-991px-width-trigger) {
    min-height: 230px // TODO: check how to use just 100%
  }
}
